.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 300px;
}