.agent-card-dropdown .btn {
    padding: 0 !important;
}

.agent-card-dropdown .btn-link {
    padding: 0 !important;
}

@media (min-width: 768px) {
    .ten-columns > .col-sm-2  {
        width: 20%;
    }
}

.agent-request-header-section{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.competitor-options-dropdown .dropdown-menu {
    left: unset !important;
    right: 0 !important;
}

.competitor-options-dropdown .btn {
    padding: 0 !important;
}

.competitor-options-dropdown .btn-link {
    padding: 0 !important;
}

.frequency-dropdown{
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
}

.frequency-dropdown .dropdown-toggle{
    height: 46px !important;
}

.frequency-dropdown  .dropdown-menu{
    z-index: 9999 !important;
}

.btn-group-days {
    display: inline;
}
.react-tagsinput{
    min-height: 48px !important;
}

.react-tagsinput--focused{
    border-color: #50a4ba !important;
}

.react-tagsinput-tag{
    background-color: #0088a9 !important;
    white-space: nowrap !important;
    vertical-align: baseline !important;
    display: inline !important;
    font-weight: 700 !important;
    color: #fff !important;
    border: none !important;
    font-size: 12px !important;
    line-height: 16px !important;
    padding: 7px 12px !important;
    border-radius: 16px !important;
    z-index: 999999;
    opacity: 1;
}

.react-tagsinput-remove{
    color: #eeeeee;
}
.react-tagsinput-remove:hover{
    color: #eeeeee;
    text-decoration: unset !important;
}

.react-tagsinput > span {
    display: flex ;
    flex-wrap: wrap;
}
.createAgentRequestProductCategory .form-control
{
z-index: 0 !important;
}
.createAgentRequestProductCategory .control-label
{
z-index: 0 !important;
}

.createAgentRequestCountry .react-select-wrapper
{
z-index: 0 !important;
}

.creatableSelectHeight__control
{
    min-height: 45px !important;
}
.creatableSelectHeight__multi-value__label
{
    min-height: 28px !important;
    align-content: center;
    font-size: 13px !important;
    font-weight: 700;
    background-color:#0088A9;
    color:white !important;
    padding-top: 5px !important;
}
.creatableSelectHeight__multi-value__remove
{
    min-height: 26px !important;
    align-content: center;
    background-color:#0088A9;
    margin: 0px !important;
    margin-left: -2px !important;
    margin-right: 3px;
    color: white !important;
}
.description-button {
    font-size: 2em;
    color: #0088a9;
    cursor: pointer;
    position: relative;
    padding-top: 5px;
    display: inline-block;
}

.request-agent-modal-description.form-group label {
  top: 8px;
}
