
.menu {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
  z-index: 1000;
}

.item {
  padding: 2px 6px;
  cursor: default;
}

.item-highlighted {
  color: white;
  background-color: #4095bf;
}

.item-selected {
  color: white;
  background-color: #4095bf;
}

.item-header {
  background-color: #eeeeee;
  color: #454545;
  font-weight: bold;
}
