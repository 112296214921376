 
  .summary-cards {
    margin-bottom: 25px;
  }
  
  .summary-cards .card-holder {
    flex: 1;
    margin-right: 7px;
    margin-left: 7px;
  }
  
  .summary-cards .card-holder:first-child {
    margin-left: 14px;
  }
  
  .summary-cards .card-holder:last-child {
    margin-right: 14px;
  }
  
  .summary-cards .card > .card-block {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .summary-cards .card > .card-block > p {
    font-size: 3em;
    color: #F0563A;
  }
  
  .card.clickable:hover {
      background-color: #D4EBF2 !important;
      cursor: pointer;
  }
  
  .activeCard {
    background-color: #B0D9E6 !important;
  }
  
  .card.not-clickable {
    border: unset;
    box-shadow: unset;
  }