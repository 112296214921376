fileUploadWrapper input,
.fileUploadWrapperLabel {
  margin: auto;
}

.fileUploadWrapper input,
.fileUploadWrapperLabel {
  margin: auto;
}

.fileUploadWrapper .newagent input
{
  background-color: lightgray;
}

.fileUploadWrapper {
  text-align: center;
  overflow: hidden;
  min-width: 300;
}

.fileUploadWrapperLabel {
  width: 90%;
  border-right: 0;
  border-radius: 2px;
  text-align: left;
  height: 50px;
  box-sizing: border-box;
  color: #b8b8b8;
  font-size: 15px;
  font-family: Helvetica, sans-serif;
  float: right;
  background: transparent;
}

.fileUploadWrapper #uploadBtn {
  height: 50px;
  width: 10%;
  float: right;
  box-sizing: border-box;
  background-color: #3986a5;
  cursor: pointer;
  text-align: center;
  color: #fff;
  font-size: 12px;
  padding: 20px 0;
  border-radius: 0 2px 2px 0;
}

.fileUploadWrapper input.HiddenInput[type="file"] {
  display: none;
}

.aligncenter {
  width: 60%;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.rdt {
  width: 100% !important;
}

.datetime {
  width: 100%;
}

.inner-content {
  background-color: #fff;
  padding: 30px 30px 60px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.add-competitor-modal-popup .modal-body
{
  overflow-y: visible !important;
}
