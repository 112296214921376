.scraping-jobs-header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.rbc-time-view .rbc-label {
    display: none;
  }
.rbc-time-view   .rbc-allday-cell {
    height: calc(100vh - 98px);
    max-height: unset;
  }
.rbc-time-view  .rbc-time-content {
    display: none;
  }
.rbc-btn-group button {
  color: #0088a9 !important;
  background: #fff !important;
  box-shadow: none;
  border-radius: 2px;
  text-shadow: none;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 600;
  padding: 9px 24px !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  font-size: 14px;
  line-height: 1.286;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.date-in-past {
  width: 14.3%;
  background: #ccc;
  border-right: solid 1px #fff;
}

.labelStyle{
  border-radius: 12px;
  font-size: 10px;
  line-height: 14px;
  padding: 5px 9px;
  display: inline-block;
  font-weight: 600;
  color: white;
}

.error-message{
  color: red;
}

.error-row {
  padding: 10px;
  border-bottom: 1px solid #e1e6eb;
}
.flexcontainer {
  display: flex !important;
  align-items: flex-start;

}
.flexcontainer .content {
padding: 5px  !important;
}

.schedulingCalendarToottip
{
  font-size:  11px !important;
  margin-bottom:  5px;
  display: flex;
  color: #6f7d88;
}
