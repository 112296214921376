.competitor-header-section{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px
}

.react-tagsinput{
    min-height: 48px !important;
}

.competitor-options-dropdown .dropdown-menu {
    left: unset !important;
    right: 0 !important;
}

.competitor-options-dropdown .btn {
    padding: 0 !important;
}

.competitor-options-dropdown .btn-link {
    padding: 0 !important;
}
.schedule-action-dropdown{
    height: 36px;
}
.schedule-action-dropdown .btn-link{
    background-color: transparent !important;
    border: 0px solid transparent !important;
}

.schedule-action-dropdown .btn.btn-link:focus{
    outline: none;
}

.schedule-action-dropdown .dropdown-menu {
    left: unset !important;
    right: 23px !important;
    height: fit-content;
}

.bulkScheduleDropdown{
    top: 17px;
    right: 10px;
}
.bulkScheduleDropdown .tooltip-arrow{
    left: 57% !important;
}
.frequency-dropdown{
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
}

.frequency-dropdown .dropdown-toggle{
    height: 46px !important;
}

.frequency-dropdown  .dropdown-menu{
    z-index: 9999 !important;
}

.btn-group-days {
    display: inline;
}


.react-tagsinput--focused{
    border-color: #50a4ba !important;
}
.react-tagsinput-tag{
    background-color: #0088a9 !important;
    white-space: nowrap !important;
    vertical-align: baseline !important;
    display: inline !important;
    font-weight: 700 !important;
    color: #fff !important;
    border: none !important;
    font-size: 12px !important;
    line-height: 16px !important;
    padding: 7px 12px !important;
    border-radius: 16px !important;
}
.react-tagsinput-remove{
    color: #eeeeee;
}
.react-tagsinput-remove:hover{
    color: #eeeeee;
    text-decoration: unset !important;
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden!important;
    text-overflow: ellipsis;
}

/**::placeholder {*/
/*    color: #8090a2;*/
/*    font-weight: 400;*/
/*    position: absolute;*/
/*    left: 16px;*/
/*    top: 16px;*/
/*    line-height: 14px;*/
/*    margin-bottom: 0;*/
/*    transition: all .2s ease-out;*/
/*    z-index: 5;*/
/*    overflow: hidden;*/
/*    white-space: nowrap;*/
/*    text-overflow: ellipsis;*/
/*    max-width: 90%;}*/
