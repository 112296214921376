.agent-card-dropdown .btn {
    padding: 0 !important;
}

.agent-card-dropdown .btn-link {
    padding: 0 !important;
}

@media (min-width: 768px) {
    .ten-columns > .col-sm-2  {
        width: 20%;
    }
}
.tableHeader
{
  font-weight: 'bold';
  color: '#3d4650'
}

.labelStyle{
    border-radius: 12px;
    font-size: 10px;
    line-height: 14px;
    padding: 5px 9px;
    display: inline-block;
    font-weight: 600;
}

.workflow-card-padding-override .card-block {
    padding: 8px !important;
}