
.ctrf-stars-container {
    margin: 10px 10px 20px 0;
}

.ctrf-stars-container svg {
  cursor: pointer;
}

.ctrf-stars-container > :last-child {
    padding-right: 0px;
}

.ctrf-stars-item {
    padding-left: 0px;
    padding-right: 10px;
}

.ctrf-question-container {

}

.ctrf-question-title {
    font-size: 16px;
}

.ctrf-question-container textarea {
    resize: vertical;
}

.ctrf-question-text-field {
    margin-top: 10px;
}

.ctrf-feedback-sticker-container {
    z-index: 100;
    position: fixed;
    bottom:10px;
    right:0;
    background-color: #41b5dc;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #188aa8;
    border-radius: 15px 0 0 15px;
    width: 100px;
    text-align: center;
    color: white;
    border-right: 0px;
}

.ctrf-feedback-sticker-container:hover {
    background-color: #edf3f8;
}

@keyframes wiggle {
    0% { transform: rotate(0deg);
        padding: 10px;
    }
    96% {
        padding: 10px;
        background-color: #41b5dc;
        border: 1px solid #188aa8;
    }
    98% {
        background-color: #188aa8;
        border: 3px solid #188aa8;
    }
    100% {
        padding: 10px;
        background-color: #41b5dc;
        border: 1px solid #188aa8;
    }
}

.ctrf-feedback-sticker-container {
    animation: wiggle 4s;
    animation-iteration-count: 3;
}

.ctrf-feedback-sticker-container:hover {
    animation: none;
    background-color: #41b5dc;
    border-color: #188aa8;
    text-decoration: underline;
    cursor: pointer;
}

.ctrf-question-free-form {
    margin-top: 10px;
}