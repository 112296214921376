.flex-container {
  display: flex;
  align-items: center;
}

.flex-child {
  display: flex;
  flex-grow: 1;
}

.flex-child-grow {
  display: flex;
  flex-grow: 1000;
}

.loudspeaker .ctrf-question-container {
  text-align: right;
  margin-bottom: 30px;
  margin-top: 5px;
}

.loudspeaker .label {
  margin-right: 2px;
  margin-bottom: 2px;
}

.loudspeaker {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.loudspeakerAnnouncement {
  padding: 10px;
  border: 1px solid rgb(225, 230, 235);
  border-radius: 4px !important;
}

.loudspeakerAnnouncement:hover {
  background-color: aliceblue;
}

.loudspeakerViewAllButton {
  display: flex;
}

.loudspeakerNoAnnouncements {
  margin-top: 15px;
  margin-bottom: 15px;
}

.loudspeakerFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.loudspeakerIcon {
  padding: 5px 10px;
}

.loudspeakerListSection__feedback-box {
  text-align: right;
}

.loudspeaker .ctrf-stars-container {
  margin: 0;
}

.loudspeaker .ctrf-question-title {
  font-size: 12px;
}

.loudspeaker .ctrf-stars-item svg {
  width: 15px;
}
.snippet-container {
  margin-bottom: 10px;
}

.loudspeakerLoudbot {
  text-align: center;
  position: sticky;
  top :0px;
  background: #ffffff;
  z-index: 100;
}

.loudspeakerLoudbot svg {
  width: 87px;
}

.loudspeakerModal .modal-body {
  padding-top: 0 !important;
}

.container1 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.loudspeaker img {
  width: 100% !important;
}

.carousel-wrapper > div {
  width: auto !important;
}

.carousel-wrapper > div > div {
  height: auto !important;
}

.carousel-wrapper .carousel.slide {
  height: auto !important;
  border-width: 0px !important;
}

.carousel-wrapper .carousel div {
  height: auto !important;
}

.carousel-wrapper .carousel-navigation.carousel-navigation-small {
    width: 100px;
    margin: auto;
}

.carousel-wrapper img {
  position:relative !important
}
